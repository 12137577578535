html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    margin: 0;
    background-color: var(--page-background-color);
    font-size: var(--base-font-size);
    line-height: 1.5;
    font-family: 'Source Sans Pro', sans-serif;
    color: var(--text-color);
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;

    /* CSS to keep footer always on bototm */
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

main {
    flex: 1;
}

/* Links */
a.class1:link {
    color: var(--text-color);
    text-decoration: none;
}

a.class1:visited {
    color: var(--text-color);
    text-decoration: none;
}

a.class1:hover {
    color: var(--text-color);
    text-decoration: none;
}

/* Schirften */
h1 {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 38px;
    font-weight: 600;
    line-height: 1.3;
}

h2 {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 28px;
    font-weight: 600;
    line-height: 1.3;
}

a {
    color: inherit;
    text-decoration: none;
}

img {
    width: 100%;
    height: auto;
    display: block;
}

.fix-body {
    overflow-y: hidden;
}

.observedElement {
    opacity: 0;
    transform: translateY(100px);
    transition:
        transform 1s,
        opacity 1s;
}

.observedElementFadeIn {
    opacity: 1;
    transform: translateY(0);
}

/* Kontaktformular */
