html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  background-color: var(--page-background-color);
  font-size: var(--base-font-size);
  color: var(--text-color);
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  font-family: Source Sans Pro, sans-serif;
  line-height: 1.5;
  display: flex;
}

main {
  flex: 1;
}

a.class1:link, a.class1:visited, a.class1:hover {
  color: var(--text-color);
  text-decoration: none;
}

h1 {
  font-family: Source Sans Pro, sans-serif;
  font-size: 38px;
  font-weight: 600;
  line-height: 1.3;
}

h2 {
  font-family: Source Sans Pro, sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.3;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
  display: block;
}

.fix-body {
  overflow-y: hidden;
}

.observedElement {
  opacity: 0;
  transition: transform 1s, opacity 1s;
  transform: translateY(100px);
}

.observedElementFadeIn {
  opacity: 1;
  transform: translateY(0);
}
/*# sourceMappingURL=index.41343af8.css.map */
